import { handleResponse, fetchIntercept } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/ad', requestOptions).then(handleResponse);
}

function info(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/ad/${id}`, requestOptions).then(handleResponse);
}

function toggle(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/ad/toggle/${id}`, requestOptions).then(handleResponse);
}

function toggleByProduct(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/ad/toggle', requestOptions).then(handleResponse);
}

function create(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/ad', requestOptions).then(handleResponse);
}

function update(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept(`/api/ad/${id}`, requestOptions).then(handleResponse);
}

function remove(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ids: data})
  };

  return fetchIntercept('/api/ad/delete', requestOptions).then(handleResponse);
}

export const autoDeploymentService = {
  list,
  info,
  toggle,
  toggleByProduct,
  create,
  update,
  remove,
};
