const date = new Date();
const year = date.getFullYear();

const APPCONFIG = {
  brand: 'DARKSiGHT',
  version: '4.2.3.8',
  user: 'Ara',
  year,
  productLink: 'https://darksight.com',
  AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
  color: {
    primary: '#025360',
    success: '#8BC34A',
    info: '#66BB6A',
    infoAlt: '#7E57C2',
    warning: '#FFCA28',
    danger: '#F44336',
    text: '#3D4051',
    gray: '#EDF0F1'
  },
  settings: {
    layoutBoxed: false,
    navCollapsed: false,
    navBehind: false,
    fixedHeader: true,
    sidebarWidth: 'middle', // small, middle, large
    colorOption: '14', // String: 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    theme: 'light', // light, gray, dark
  },
  score: {
    low: {name: 'Low', color: '#50fb50'},
    medium: {name: 'Medium', color: '#bff943'},
    high: {name: 'High', color: '#fffc00'},
    very_high: {name: 'Very High', color: '#ff9400'},
    critical: {name: 'Critical', color: '#ff2400'},
    unknown: {name: 'Unknown', color: '#a8a8a8'},
  },
  status: {
    INS: '#e94b3b',
    EOL: '#ffca28',
    DSC: '#91bcd1',
    PAT: '#66bb6a',
    VUL: '#e94b3b',
  },
  articleStatus: {
    Unknown: '#a8a8a8',
    Required: '#e94b3b',
    'Not Required': '#ffca28',
    Installed: '#66bb6a',
  },
  feature: {
    reportOveriew: 'REPORT_OVERVIEW',
    stagingDeployment: 'STAGING_DEPLOYMENT',
    unauthorizedSoftware: 'UNAUTHORIZED_SOFTWARE',
    nonCompliant: 'NON_COMPLIANT',
    driver: 'DRIVER',
    linux: 'LINUX',
    vmware: 'VMWARE',
    runUser: 'RUN_USER',
    intune: 'INTUNE',
  }
};

export default APPCONFIG;
