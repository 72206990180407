import {
  CONFIG_REQUEST,
  CONFIG_SUCCESS,
  CONFIG_FAILURE,
} from '../constants/ActionTypes';

const CONFIG_INITIAL_STATE = {
  patchApproval: true,
};

const config = (state = CONFIG_INITIAL_STATE, action = null) => {
  switch (action.type) {
    case CONFIG_REQUEST: {
      return state;
    }
    case CONFIG_SUCCESS:
      return {
        ...state,
        ...action.data,
      };
    case CONFIG_FAILURE:
      return state;
    default:
      return state;
  }
};

export default config;
