import { fetchIntercept, handleResponse } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/vm', requestOptions).then(handleResponse);
}

function set(body = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({...body})
  };

  return fetchIntercept('/api/vm', requestOptions).then(handleResponse);
}

function removeVCenter(vCenterId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({id: vCenterId})
  };

  return fetchIntercept('/api/vm', requestOptions).then(handleResponse);
}

function getDatacenters(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/datacenter`, requestOptions).then(handleResponse);
}

function getDatacenterDetails(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/datacenter/${ref}`, requestOptions).then(handleResponse);
}

function getHostsAndFolders(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/datacenter/${ref}/host-folder`, requestOptions).then(handleResponse);
}

function getHostsAndSubFolders(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/folder/${ref}/host-folder`, requestOptions).then(handleResponse);
}

function getHostDetails(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/host/${ref}`, requestOptions).then(handleResponse);
}

function getConsoleSnapshots(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/console-snapshot/${ref}`, requestOptions).then(handleResponse);
}

function deleteSnapshots(id, refs) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({refs})
  };

  return fetchIntercept(`/api/vm/${id}/snapshot/`, requestOptions).then(handleResponse);
}

function getVirtualMachines(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/host/${ref}/virtual-machine`, requestOptions).then(handleResponse);
}

function getDatastoreInfo(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/datastore/${id}/${ref}`, requestOptions).then(handleResponse);
}

function getVirtualMachineDetails(id, ref) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/vm/${id}/virtual-machine/${ref}`, requestOptions).then(handleResponse);
}

export const vmService = {
  list,
  set,
  removeVCenter,
  getDatacenters,
  getDatacenterDetails,
  getHostsAndFolders,
  getHostsAndSubFolders,
  getHostDetails,
  getVirtualMachines,
  getDatastoreInfo,
  getVirtualMachineDetails,
  getConsoleSnapshots,
  deleteSnapshots,
};
