import * as types from '../constants/ActionTypes';
import {patchApprovalService} from '../services';

function request() { return { type: types.CONFIG_REQUEST }; }
function success(data) { return { type: types.CONFIG_SUCCESS, data }; }
function failure(error) { return { type: types.CONFIG_FAILURE, error }; }

function patchApproval() {

  return (dispatch) => {
    dispatch(request());

    patchApprovalService.getConfigs()
      .then((res) => {
        if (res.error) throw new Error(res.error);
        dispatch(success({
          patchApproval: res.data,
        }));
      })
      .catch((e) => {
        dispatch(failure(e.message));
      });
  };

}

function setPatchApproval(state) {
  return (dispatch) => {
    patchApprovalService.setConfigs(state)
      .then((res) => {
        if (res.error) throw new Error(res.error);
        dispatch(success({ patchApproval: state.enabled }));
      })
      .catch((e) => {
        dispatch(failure(e.message));
      });
  };
}

export const configActions = {
  patchApproval,
  setPatchApproval,
};
