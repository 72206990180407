import { fetchIntercept, handleResponse } from '../helpers';

function drivers(type) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/driver?source=${type}`, requestOptions).then(handleResponse);
}

function driversByHost(id) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/driver/${id}`, requestOptions).then(handleResponse);
}

function driverHosts(type, data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept(`/api/driver/hosts/${type}`, requestOptions).then(handleResponse);
}

export const driverService = {
  drivers,
  driversByHost,
  driverHosts,
};
