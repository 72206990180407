import { fetchIntercept, handleResponse } from '../helpers';

function packages() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/packages', requestOptions).then(handleResponse);
}

function removePackages(selected) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ paths: selected })
  };

  return fetchIntercept('/api/sccm/packages', requestOptions).then(handleResponse);
}

function deleteDeployments(selected) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: selected })
  };

  return fetchIntercept('/api/sccm/deployments-delete', requestOptions).then(handleResponse);
}

function collections() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/collections', requestOptions).then(handleResponse);
}

function resources() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/resources', requestOptions).then(handleResponse);
}

function systemConsoleUsers() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/system-console-users', requestOptions).then(handleResponse);
}

function overview() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/report/overview', requestOptions).then(handleResponse);
}

function membersByCollectionId(collectionId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/sccm/user/collection/${collectionId}`, requestOptions).then(handleResponse);
}

function membersByCollectionIds(collectionIds) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ collectionIds })
  };

  return fetchIntercept('/api/sccm/collections/members', requestOptions).then(handleResponse);
}

function createCollection(collectionName) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ collectionName })
  };

  return fetchIntercept('/api/sccm/collection', requestOptions).then(handleResponse);
}

function deleteCollections(collectionIds) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ collectionIds })
  };

  return fetchIntercept('/api/sccm/collections-delete', requestOptions).then(handleResponse);
}

function attachMembers(collection, members) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ collection, members })
  };

  return fetchIntercept('/api/sccm/attach', requestOptions).then(handleResponse);
}

function deattachMembers(collection, members) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ collection, members })
  };

  return fetchIntercept('/api/sccm/deattach', requestOptions).then(handleResponse);
}

function clientOperation(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/client-operation', requestOptions).then(handleResponse);
}

function distributionPoints() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/distribution-points', requestOptions).then(handleResponse);
}

function runSummarization(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/sccm/summarization', requestOptions).then(handleResponse);
}

function refreshSummarization(deploymentId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/sccm/summarization/${deploymentId}`, requestOptions).then(handleResponse);
}

function sync() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/sync', requestOptions).then(handleResponse);
}

function lastSync() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/last-sync', requestOptions).then(handleResponse);
}

export const sccmService = {
  packages,
  removePackages,
  deleteDeployments,
  overview,
  membersByCollectionId,
  membersByCollectionIds,
  collections,
  resources,
  systemConsoleUsers,
  createCollection,
  attachMembers,
  deattachMembers,
  deleteCollections,
  clientOperation,
  distributionPoints,
  runSummarization,
  refreshSummarization,
  sync,
  lastSync,
};
