import { fetchIntercept, handleDownload, handleResponse } from '../helpers';

function startScan(collectionIds, level = 'normal', driver = false) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ collectionIds, level, driver })
  };

  return fetchIntercept('/api/sccm/scan', requestOptions).then(handleResponse);
}

function stopScan() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/scan', requestOptions).then(handleResponse);
}

function scanStatus() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/sccm/scan-status', requestOptions).then(handleResponse);
}

function summarize(args) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/scan/summarize?from=${args.from}&to=${args.to}`, requestOptions).then(handleResponse);
}

function scanList() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/scan/list', requestOptions).then(handleResponse);
}

function downloadCsv(id) {
  const requestOptions = {
    method: 'GET',
  };

  fetchIntercept(`/api/scan/csv/${id}`, requestOptions).then(handleDownload);
}

function downloadArchive(args) {
  const requestOptions = {
    method: 'GET',
  };

  fetchIntercept(`/api/scan/csv?from=${args.from}&to=${args.to}`, requestOptions).then(handleDownload);
}

function downloadArchivePdf(args) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ date: new Date() })
  };

  fetchIntercept(`/api/scan/pdf?from=${args.from}&to=${args.to}`, requestOptions).then(handleDownload);
}

function scanDelete(selected) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ selected })
  };

  return fetchIntercept('/api/scan/delete', requestOptions).then(handleResponse);
}

export const scanService = {
  startScan,
  stopScan,
  scanStatus,
  summarize,
  scanList,
  downloadCsv,
  downloadArchive,
  downloadArchivePdf,
  scanDelete,
};
