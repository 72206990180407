import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import settings from './settings';
import auth from './auth';
import registration from './registration';
import alert from './alert';
import scan from './scan';
import {plugin, sync} from './sccm';
import system from './system';
import config from './config';

const reducers = {
  routing: routerReducer,
  settings,
  auth,
  registration,
  alert,
  scan,
  plugin,
  sync,
  system,
  config,
};

export default combineReducers(reducers);
