import { handleResponse, fetchIntercept } from '../helpers';

function getConfigs() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/intune/config', requestOptions).then(handleResponse);
}

function setConfigs(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/intune/config', requestOptions).then(handleResponse);
}

function groups() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/intune/group', requestOptions).then(handleResponse);
}

function members(groupId) {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept(`/api/intune/group/${groupId}`, requestOptions).then(handleResponse);
}

function scan(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/intune/scan', requestOptions).then(handleResponse);
}

function scanStatus() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetchIntercept('/api/intune/scan-status', requestOptions).then(handleResponse);
}

export const intuneService = {
  getConfigs,
  setConfigs,
  groups,
  members,
  scan,
  scanStatus,
};
