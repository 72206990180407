import { fetchIntercept, handleResponse } from '../helpers';

function list() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/patch-approval', requestOptions).then(handleResponse);
}

function setApprovalStatus(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/patch-approval', requestOptions).then(handleResponse);
}

function requestApproval(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/patch-approval/request', requestOptions).then(handleResponse);
}

function getConfigs() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/patch-approval/config', requestOptions).then(handleResponse);
}

function setConfigs(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept('/api/patch-approval/config', requestOptions).then(handleResponse);
}

function getUserConfigs() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/patch-approval/user', requestOptions).then(handleResponse);
}

function setUserConfigs(id, data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetchIntercept(`/api/patch-approval/user/${id}`, requestOptions).then(handleResponse);
}

export const patchApprovalService = {
  list,
  setApprovalStatus,
  requestApproval,
  getConfigs,
  setConfigs,
  getUserConfigs,
  setUserConfigs,
};
