import { fetchIntercept, handleResponse } from '../helpers';

function save(scheduleData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ scheduleData })
  };

  return fetchIntercept('/api/schedule', requestOptions).then(handleResponse);
}

function next() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/schedule/next', requestOptions).then(handleResponse);
}

function schedules() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchIntercept('/api/schedule', requestOptions).then(handleResponse);
}

function scheduleCreate(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept('/api/schedule', requestOptions).then(handleResponse);
}

function scheduleDelete(ids) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids })
  };

  return fetchIntercept('/api/schedule', requestOptions).then(handleResponse);
}

function scheduleUpdate(id, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  return fetchIntercept(`/api/schedule/${id}`, requestOptions).then(handleResponse);
}

export const scheduleService = {
  save,
  next,
  schedules,
  scheduleCreate,
  scheduleDelete,
  scheduleUpdate,
};
