export const getApprovalStatusLabel = (status) => {
  switch (status && status.toLowerCase()) {
    case 'requested':
      return 'Pending';
    case 'approved':
      return 'Approved once';
    case 'approved2':
      return 'Approved always';
    case 'rejected':
      return 'Rejected';
    default:
      return 'Not requested';
  }
};
