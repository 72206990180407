export const TOGGLE_BOXED_LAYOUT = 'TOGGLE_BOXED_LAYOUT';
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const TOGGLE_NAV_BEHIND = 'TOGGLE_NAV_BEHIND';
export const TOGGLE_FIXED_HEADER = 'TOGGLE_FIXED_HEADER';
export const CHANGE_SIDEBAR_WIDTH = 'CHANGE_SIDEBAR_WIDTH';
export const CHANGE_COLOR_OPTION = 'CHANGE_COLOR_OPTION';
export const CHANGE_THEME = 'CHANGE_THEME';

export const USERS_REGISTER_REQUEST = 'USERS_REGISTER_REQUEST';
export const USERS_REGISTER_SUCCESS = 'USERS_REGISTER_SUCCESS';
export const USERS_REGISTER_FAILURE = 'USERS_REGISTER_FAILURE';

export const USERS_LOGIN_REQUEST = 'USERS_LOGIN_REQUEST';
export const USERS_LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
export const USERS_LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';

export const USERS_LOGOUT_REQUEST = 'USERS_LOGOUT_REQUEST';
export const USERS_LOGOUT_SUCCESS = 'USERS_LOGOUT_SUCCESS';
export const USERS_LOGOUT_FAILURE = 'USERS_LOGOUT_FAILURE';

export const USERS_LOGOUT = 'USERS_LOGOUT';

export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

export const SCCM_SCAN_STATUS_REQUEST = 'SCCM_SCAN_STATUS_REQUEST';
export const SCCM_SCAN_STATUS_SUCCESS = 'SCCM_SCAN_STATUS_SUCCESS';
export const SCCM_SCAN_STATUS_FAILURE = 'SCCM_SCAN_STATUS_FAILURE';

export const SCCM_LAST_SYNC_REQUEST = 'SCCM_LAST_SYNC_REQUEST';
export const SCCM_LAST_SYNC_SUCCESS = 'SCCM_LAST_SYNC_SUCCESS';
export const SCCM_LAST_SYNC_FAILURE = 'SCCM_LAST_SYNC_FAILURE';

export const SCCM_TOGGLE_STATUS = 'SCCM_TOGGLE_STATUS';

export const SCCM_PLUGIN_VERSION = 'SCCM_PLUGIN_VERSION';

export const SYSTEM_INFO_REQUEST = 'SYSTEM_INFO_REQUEST';
export const SYSTEM_INFO_SUCCESS = 'SYSTEM_INFO_SUCCESS';
export const SYSTEM_INFO_FAILURE = 'SYSTEM_INFO_FAILURE';

export const CONFIG_REQUEST = 'CONFIG_REQUEST';
export const CONFIG_SUCCESS = 'CONFIG_SUCCESS';
export const CONFIG_FAILURE = 'CONFIG_FAILURE';
